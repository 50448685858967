<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
  <div class="form-group">
    <b-col cols="12">
      <toggle-button
        class="gelatinosaBtn"
        v-model="form.fields.usarGelatinosa"
        @change="handleToggle('usarGelatinosa')"
        :sync="true"
        :height="24"
        :disabled="!canEdit"
        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
      />
      <span class="autoFillBtnLabel">Lentes de contato gelatinosa</span>
    </b-col>
    <b-col cols="12">
        <toggle-button
          class="rigidaBtn"
          v-model="form.fields.usarRigida"
          @change="handleToggle('usarRigida')"
          :sync="true"
          :height="24"
          :disabled="!canEdit"
          :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
        />
        <span class="autoFillBtnLabel">Lentes de contato rígida</span>
    </b-col>
  </div>
    <b-row >
      <b-col v-if="form.fields.usarGelatinosa" class="mt-2">
        <span class="label">Lentes de contato gelatinosa</span>
        <b-row >
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div class="custom-input eye-box-container">
                    <div class="text-area type-2 with-br" @click="openOptions('esfericoDireito')">
                      <span >Esférico</span>
                      <div >
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="form.fields.olhoDireitoEsferico"
                          :disabled="!canEdit"
                          @input="el => updateForm('olhoDireitoEsferico', el.target.value)"
                          @blur="updateMedicalRecord(form)"
                        >
                        <MultipleEyesOptions
                          v-if="this.activeEyeBox ==='esfericoDireito' && canEdit"
                          :form="form"
                          activeInput="esfericoDireito"
                          :setActiveInput="this.setActiveEyeBox"
                          @select="({ key, value }) => this.updateForm(key, value)"
                          @blur="updateMedicalRecord(form)"
                          :options="form.fields.olhoDireitoEsferico ?
                            (parseFloat(form.fields.olhoDireitoEsferico.replace(',', '.')) < 0.00 ?
                              optionsNegative :
                                optionsPositive) :
                              optionsPositive"
                          />
                      </div>
                    </div>
                    <div class="eyeValueChange" v-if="canEdit">
                      <div>
                        <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoEsferico', null);"><p>+</p></button>
                      </div>
                      <div>
                        <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoEsferico', null)"><p>-</p></button>
                      </div>
                    </div>
                  </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('cilindricoDireito')">
                    <span>Cilíndrico</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoCilindrico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoCilindrico', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='cilindricoDireito' && canEdit"
                        :form="form"
                        activeInput="cilindricoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoDireitoCilindrico !== null ?
                          (parseFloat(form.fields.olhoDireitoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                              optionsPositive) :
                          optionsNegative"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoCilindrico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoCilindrico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('eixoDireito')">
                    <span>Eixo</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoEixo"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoEixo', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='eixoDireito' && canEdit"
                        :form="form"
                        activeInput="eixoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 1, 'olhoDireitoEixo', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 1, 'olhoDireitoEixo', 'eixo')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('AdicaoDireito')">
                    <span>Adição</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoAdicao"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAdicao', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='AdicaoDireito' && canEdit"
                        :form="form"
                        activeInput="AdicaoDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoDireitoAdicao',  null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoDireitoAdicao', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('acuidadeVisualDireito')">
                    <span>Acuidade Visual</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoAV"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoDireitoAV', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='acuidadeVisualDireito' && canEdit"
                        :form="form"
                        activeInput="acuidadeVisualDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsAcuidade"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('esfericoEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEsferico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEsferico', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      />
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox === 'esfericoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="esfericoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoEsferico ?
                          (parseFloat(form.fields.olhoEsquerdoEsferico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                              optionsPositive) :
                          optionsPositive"
                        />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoEsferico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoEsferico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('cilindricoEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoCilindrico"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoCilindrico', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='cilindricoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="cilindricoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                        :options="form.fields.olhoEsquerdoCilindrico !== null ?
                          (parseFloat(form.fields.olhoEsquerdoCilindrico.replace(',', '.')) < 0.00 ?
                            optionsNegative :
                              optionsPositive) :
                            optionsNegative"
                        />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoCilindrico', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoCilindrico', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('eixoEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoEixo"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoEixo', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='eixoEsquerdo' && canEdit"
                        :form="form" activeInput="eixoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsEixo"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 1, 'olhoEsquerdoEixo', 'eixo');"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 1, 'olhoEsquerdoEixo', 'eixo')"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('AdicaoEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoAdicao"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAdicao', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='AdicaoEsquerdo' && canEdit"
                        :form="form"
                        activeInput="AdicaoEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsPositive"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                  <div class="eyeValueChange" v-if="canEdit">
                    <div>
                      <button class="btn" @click="changeInputValue('increase', 0.25, 'olhoEsquerdoAdicao', null);"><p>+</p></button>
                    </div>
                    <div>
                      <button class="btn" @click="changeInputValue('decrease', 0.25, 'olhoEsquerdoAdicao', null)"><p>-</p></button>
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('acuidadeVisualEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoAV"
                        :disabled="!canEdit"
                        @input="el => debounceInput('olhoEsquerdoAV', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='acuidadeVisualEsquerdo' && canEdit"
                        :form="form"
                        activeInput="acuidadeVisualEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsAcuidade"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            v-if="canEdit"
            class="btn blue-underline button-copy"
            @click="copyEyeValues"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <v-copy-icon />
          </button>
        </b-row>
        <b-row>
          <b-col class="use-refraction-container">
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Est'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Est')"
                :disabled="!canEdit"
              />
              <div>Usar valores da refração estática</div>
            </div>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Din'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Din')"
                :disabled="!canEdit"
              />
              <div>Usar valores da refração dinâmica</div>
            </div>
            <div class="checkbox-wrapper">
              <Check
                :value="form.fields.usarValoresRefracao === 'Len'"
                @input="(checked) => dispatchGetRefractionValues(checked, 'Len')"
                :disabled="!canEdit"
              />
              <div>Usar valores da lensometria</div>
            </div>
          </b-col>
        </b-row>
        <div class="form-group mb-0 break-spaces">
          <v-text-area
            id="marcaDaLente"
            rows="1"
            v-model="form.fields.marcaDaLente"
            :readonly="!canEdit"
            @input="value => debounceChangeValue('marcaDaLente', value)"
            @blur="updateMedicalRecord(form)"
            type="text"
            class="form-control"
            placeholder="Marca da Lente"
          />
        </div>
        <b-row>
          <b-col class="guidance-container">
            <div class="form-group mb-0">
              <VueEditor
                id="lentes-de-contato-orientacao"
                class="vue_edition"
                v-model="form.fields.orientacao"
                :editorToolbar="customToolbar"
                @input="value => debounceChangeValue('orientacao', value)"
                :disabled="!canEdit"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row >
      <b-col v-if="form.fields.usarRigida" class="mt-4">
        <span class="label">Lentes de contato rígida</span>
        <b-row >
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                  <div class="eye-area" >
                    <EyeFill /> D
                  </div>
                  <div class="custom-input eye-box-container">
                    <div class="text-area type-2 with-br" @click="openOptions('modeloDireito')">
                      <span>Modelo</span>
                      <div >
                        <input
                          autocomplete="off"
                          type="text"
                          class="form-control text-center"
                          :value="form.fields.olhoDireitoModelo"
                          :disabled="!canEdit"
                          @input="el => updateForm('olhoDireitoModelo', el.target.value)"
                          @blur="updateMedicalRecord(form)"
                        >
                      </div>
                    </div>
                  </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('curvaDireito')">
                    <span>Curva base (CB)</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoCurva"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoDireitoCurva', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('diametroDireito')">
                    <span>Diâmetro</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoDiametro"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoDireitoDiametro', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('grauDireito')">
                    <span>Grau</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoGrau"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoDireitoGrau', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('fabricanteDireito')">
                    <span>Fabricante</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoFabricante"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoDireitoFabricante', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('acuidadeVisualRigidaDireito')">
                    <span>Acuidade Visual</span>
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireitoAVRigida"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoDireitoAVRigida', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='acuidadeVisualRigidaDireito' && canEdit"
                        :form="form"
                        activeInput="acuidadeVisualRigidaDireito"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsAcuidade"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('modeloEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoModelo"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoModelo', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('curvaEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoCurva"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoCurva', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('diametroEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoDiametro"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoDiametro', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('grauEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoGrau"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoGrau', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br" @click="openOptions('fabricanteEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoFabricante"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoFabricante', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                    </div>
                  </div>

                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2" @click="openOptions('acuidadeVisualRigidaEsquerdo')">
                    <div >
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdoAVRigida"
                        :disabled="!canEdit"
                        @input="el => updateForm('olhoEsquerdoAVRigida', el.target.value)"
                        @blur="updateMedicalRecord(form)"
                      >
                      <MultipleEyesOptions
                        v-if="this.activeEyeBox ==='acuidadeVisualRigidaEsquerdo' && canEdit"
                        :form="form"
                        activeInput="acuidadeVisualRigidaEsquerdo"
                        :setActiveInput="this.setActiveEyeBox"
                        :options="optionsAcuidade"
                        @select="({ key, value }) => this.updateForm(key, value)"
                        @blur="updateMedicalRecord(form)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            v-if="canEdit"
            class="btn blue-underline button-copy"
            @click="copyEyeValuesRigida"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <v-copy-icon />
          </button>
        </b-row>
        <b-row>
          <b-col class="guidance-container">
            <div class="form-group mb-0">
              <VueEditor
                id="lentes-de-contato-orientacao-rigida"
                class="vue_edition"
                v-model="form.fields.orientacaoRigida"
                :editorToolbar="customToolbar"
                @input="value => debounceChangeValue('orientacaoRigida', value)"
                :disabled="!canEdit"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import MultipleEyesOptions from '@/components/Attendance/Forms/Components/MultipleEyesOptions'
import EyeFill from '@/assets/icons/eye-fill.svg'
import Check from '@/components/General/Check'
import { VueEditor } from "vue2-editor"
import Copy from '@/assets/icons/copy.svg'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    FormWrapper,
    MultipleEyesOptions,
    EyeFill,
    Check,
    VueEditor,
    'v-copy-icon' : Copy,
    'v-text-area': TextArea,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.lentesDeContato,
    })
  },
  
  created() {
    this.form.fields.usarRigida = false
    this.form.fields.usarGelatinosa = false
    this.debounceChangeValue = debounce(this.onInput, 500)
  },

  data() {
    return {
      activeEyeBox: String,
      optionsPositive: [['+0,25', '+0,50', '+0,75', '+1,00', '+1,25'], ['+1,50', '+1,75', '+2,00', '+2,25', '+2,50'], ['+2,75', '+3,00', '+3,25', '+3,50', '+3,75'], ['+4,00', '+4,25', '+4,50', '+4,75', '+5,00']],
      optionsNegative: [['-0,25', '-0,50', '-0,75', '-1,00', '-1,25'], ['-1,50', '-1,75', '-2,00', '-2,25', '-2,50'], ['-2,75', '-3,00', '-3,25', '-3,50', '-3,75'], ['-4,00', '-4,25', '-4,50', '-4,75', '-5,00']],
      optionsEixo: [['5º', '10º', '15º', '20º', '25º', '30º'], ['35º', '40º', '45º', '50º', '55º', '60º'], ['65º', '70º', '75º', '80º', '85º', '90º'], ['95º', '100º', '105º', '110º', '115º', '120º'], ['125º', '130º', '135º', '140º', '145º', '150º'], ['155º', '160º', '165º', '170º', '175º', '180º']],
      optionsAcuidade: [
        ['20/10', '20/15', '20/20', '20/25', '20/30'],
        ['20/40', '20/50', '20/70', '20/100', '20/200'],
        ['20/400', 'CD 3m', 'CD 2m', 'CD 1m', 'CD 0,5m'],
        ['MM', 'PL', 'SPL']
      ],
      customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "underline", "strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
    }
  },
  mounted() {
    this.debounceInput = debounce(this.updateForm, 300)
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/lentesDeContato', ['handleFields', 'handleProps']),
    ...mapActions('attendance/form/refracaoEstatica', { useEstaticValuesOnLenses: 'useValuesOnLenses' }),
    ...mapActions('attendance/form/refracaoDinamica', { useDinamicValuesOnLenses: 'useValuesOnLenses' }),
    ...mapActions('attendance/form/lensometria', { useLensometricValuesOnLenses: 'useValuesOnLenses' }),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    onInput(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    openOptions(input){
        this.activeEyeBox === input ? this.activeEyeBox = null : this.activeEyeBox = input
    },
    setActiveEyeBox(BoxName){
      this.activeEyeBox = BoxName
    },
    copyEyeValues(){
      this.updateForm('olhoEsquerdoEsferico', this.form.fields.olhoDireitoEsferico)
      this.updateForm('olhoEsquerdoCilindrico', this.form.fields.olhoDireitoCilindrico)
      this.updateForm('olhoEsquerdoEixo', this.form.fields.olhoDireitoEixo)
      this.updateForm('olhoEsquerdoAdicao', this.form.fields.olhoDireitoAdicao)
      this.updateForm('olhoEsquerdoAV', this.form.fields.olhoDireitoAV)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },

    copyEyeValuesRigida(){
      this.updateForm('olhoEsquerdoModelo', this.form.fields.olhoDireitoModelo)
      this.updateForm('olhoEsquerdoCurva', this.form.fields.olhoDireitoCurva)
      this.updateForm('olhoEsquerdoDiametro', this.form.fields.olhoDireitoDiametro)
      this.updateForm('olhoEsquerdoGrau', this.form.fields.olhoDireitoGrau)
      this.updateForm('olhoEsquerdoFabricante', this.form.fields.olhoDireitoFabricante)
      this.updateForm('olhoEsquerdoAVRigida', this.form.fields.olhoDireitoAVRigida)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    changeInputValue(change, amount, key, type){
      if(this.form.fields[key] === '' || this.form.fields[key] === null){
        this.form.fields[key] = '0'
      }
      if(!(isNaN(parseFloat(this.form.fields[key])))){
          if(type === 'eixo'){
              if(change === 'increase'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(0)).replace('.', ','))

              } else if(change === 'decrease'){
                this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(0)).replace('.', ','))
              }
              if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
                this.updateForm(key, this.form.fields[key]+'º')
              }
          }

          else if(!(isNaN(parseFloat(this.form.fields[key])))){
            if(change === 'increase'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) + amount).toFixed(2)).replace('.', ','))

            } else if(change === 'decrease'){
              this.updateForm(key, ((parseFloat(this.form.fields[key].replace(',', '.')) - amount).toFixed(2)).replace('.', ','))
            }
            if(parseFloat(this.form.fields[key].replace(',', '.')) > 0){
              this.updateForm(key, '+'+this.form.fields[key])
            }
        }
        this.updateMedicalRecord(this.form)
      }
    },
    onChange() {
      // this.attendanceReview.prescricaoDeLentes = Object.keys(this.form.fields).some(key =>this.form.fields[key]);
    },
    dispatchGetRefractionValues(checked, suffix) {
      switch (suffix) {
        case 'Est':
          this.useEstaticValuesOnLenses(checked)
          break;
        case 'Din':
          this.useDinamicValuesOnLenses(checked)
          break;
        case 'Len':
            this.useLensometricValuesOnLenses(checked)
            break;
        default:
          break;
      }
    },
    handleToggle(key){
      this.updateForm(key, this.form.fields[key])
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .custom-input-group {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral-300);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    .custom-input {
      flex: 1;
      height: 38px;
      border: 8px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--neutral-300);
      justify-content: center;

      .eye-area {
        width: 60px;
        background-color: var(--neutral-100);
        border-right: 1px solid var(--neutral-300);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px 0 0 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        svg {
          width: 16px;
          height: 16px;
          fill: var(--type-active);
          margin-right: 4px;
        }
      }

      &.no-bb {
        border-bottom: 0;

        .eye-area {
          border-radius: 0 0 0 8px;
        }
      }

      .text-area {
        flex: 1;
        line-height: 55px;
        margin-left: 16px;
        font-weight: 400;
        font-size: 16px;
        color: var(--type-active);

        .form-control {
          border: 0 !important;
          border-radius: 0 !important;

          &.with-bbr {
            border-radius: 0 0 8px 0 !important;
          }

          &.with-btr {
            border-radius: 0 8px 0 0 !important;
          }
        }

        &.type-2 {
          position: relative;
          margin-left: 0;
          text-align: center;

          span {
            width: 100%;
            position: absolute;
            top: -19px;
            left: 0;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: var(--type-active);
          }
        }
        &.with-br {
          border-right: 1px solid var(--neutral-300) !important;
        }

        &.with-brtr {
          border-radius: 0 8px 0 0 !important;
        }

        &.with-brbr {
          border-radius: 0 0 8px 0 !important;
        }
      }
    }
  }
  .eyeValueChange {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 !important;
    position: relative;
    margin-left: -30px !important;
    z-index: 5;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 30px;

      button {
        height: 20px;
        width: 30px;
        color: var(--greys-60);
        background-color: #fff;
        position: relative !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1.5px solid var(--blue-100) !important;

        p {
          font-weight: 700;
        }

      }
    }
  }
  .eye-box-container {
    .eyeValueChange {
      visibility: hidden;
    }

    &:hover {
      .eyeValueChange {
        visibility: visible;
      }
    }
  }
  .use-refraction-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
  }
  .guidance-container {
    margin-top: 8px;
  }
  .label {
    color: var(--blue-700);
    font-weight: 600;
  }
  .vue_edition {
    background-color: white;

    :deep(.ql-editor) {
      min-height: 100px !important;
    }
  }
</style>
